interface SubscriptionContractFromQuery {
  node: {
    id: string;
    orders: {
      edges: {
        node: {
          id: string;
        };
      }[];
      pageInfo: {
        endCursor: string;
        hasNextPage: boolean;
      };
    };
  };
}

export const getOrderCountFromSubscriptionContracts = (contracts: SubscriptionContractFromQuery[]) => {
  const orders = contracts.reduce((acc, curr) => {
    const edges = curr?.node?.orders?.edges;
    if (edges) {
      return acc + edges.length;
    }
    return acc;
  }, 0);

  return orders;
};
